import { Button, Input } from "@nextui-org/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase"; // Import Firebase Authentication
import { signInWithEmailAndPassword } from "firebase/auth";

export const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [uid, setUid] = useState(null); // State to store UID

  const showPass = () => {
    setShowPassword(!showPassword);
  };

  const handleInput = async () => {
    try {
      // Menggunakan Firebase untuk login
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("User logged in:", user);
      
      // Menyimpan UID ke dalam state
      setUid(user.uid);

      // Atau bisa menggunakan localStorage untuk menyimpan UID agar tetap ada setelah reload
      localStorage.setItem("userUID", user.uid);

      navigate("/home"); // Setelah login sukses, arahkan ke halaman home
    } catch (error) {
      console.error(error);
      alert("Email atau password salah");
    }
  };

  return (
    <section className="background">
      <div className="flex justify-center items-center h-full">
        <div className="backdrop-blur-sm flex flex-col justify-center items-center bg-white/60 mobile:w-[326px] mobile:h-[292px] desktop:w-[600px] desktop:h-[600px] rounded-[16px] border-2 border-[#F8F9FD] ">
          <div className="flex flex-col justify-center items-center  ">
            <p className="font-semibold text-[32px] desktop:text-[52px] text-[#4C9514] mb-6 ">Selamat Datang</p>
            <div className="mobile:w-[246px] desktop:w-[432px] space-y-4 mobile:mb-4 desktop:mb-10 relative">
              <Input onChange={(e) => setEmail(e.target.value)} id="email" color="white" className="placeholder-white  mobile:h-[40px] desktop:h-[60px] rounded-[2px] " type="email" label="Email" />
              <Input onChange={(e) => setPassword(e.target.value)} id="password" className="mobile:h-[40px] desktop:h-[60px]" type={showPassword ? "text" : "password"} label="Password" />
              <span className="absolute mobile:bottom-2 desktop:bottom-5 right-4 cursor-pointer " onClick={showPass}>
                {showPassword ? (
                  <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                  </svg>
                ) : (
                  <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.4 38.6-63.2l-53.8-42.2C48.6 187.6 33.5 220.7 22.9 247.4c-10.7 26.8-13.2 56.6-4.1 85.7c9.1 29.1 28.6 55.4 55.2 73.9l232.6 181.1c37.9 29.6 85.1 46.9 135.1 46.9c74.2 0 141.2-38.5 180.8-103.4c40.4-65.5 39.5-148.7-3.4-212.2zm-156.6 5.5C266 365.9 294.9 384 320 384c44.2 0 80-35.8 80-80c0-35.3-28.7-64-64-64c-5.5 0-10.9 .7-16 2c0-.7 0-1.3 0-2c0-44.2-35.8-80-80-80c-5.5 0-10.9 .7-16 2c0 .7 0 1.3 0 2c0 35.3 28.7 64 64 64c-3.4 5.5-6.3 11.6-8.4 17.5z" />
                  </svg>
                )}
              </span>
            </div>
            <Button color="success" size="lg" onClick={handleInput} className="w-full mobile:h-[40px] desktop:h-[60px] rounded-[16px]">Masuk</Button>
          </div>
        </div>
      </div>
    </section>
  );
};
