import React, { useState } from "react";
import { Navbar, NavbarBrand, NavbarMenuToggle, NavbarMenuItem, NavbarMenu, NavbarContent, NavbarItem, Link, Button } from "@nextui-org/react";
import Profil from "../img/profil.svg";
import logo from "../img/Group 19.svg";
import Home1 from "../img/home1.svg";
import History1 from "../img/history1.svg";
import Status1 from "../img/status1.svg";
import Logout1 from "../img/logout.svg";
import LogoutPop from "../img/Logout.png";
import { useLocation, useNavigate } from "react-router-dom";

export const NavbarResponsive = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = (path) => {
    navigate(path);
    setActivePage(path);
  };

  return (
    <div className="desktop:hidden w-full">
      <Navbar isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>
        <NavbarContent className="mobile:flex" justify="start">
          <NavbarMenuToggle color="#5BB318" aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
        </NavbarContent>

        <NavbarContent className="mobile:flex" justify="center">
          <NavbarBrand>
            <p className="font-semibold text-[#5BB318] text-lg ">
              {activePage === "/Home" && "Dashboard"}
              {activePage === "/Riwayat" && "Riwayat"}
              {activePage === "/Notifikasi" && "Notifikasi"}
              {activePage === "/status" && "Status"}
            </p>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent justify="end">
          <NavbarItem className="hidden desktop:hidden">
            <Link href="#">Login</Link>
          </NavbarItem>
          <NavbarItem>
            <div className="flex gap-4  desktop:hidden">
              {/* <img className="w-[1.3rem]" src={Notifikasi} alt="notifikasi" /> */}
              <img className="w-[2rem]" src={Profil} alt="Profil" />
            </div>
          </NavbarItem>
        </NavbarContent>

        <NavbarMenu>
          <NavbarMenuItem>
            <img className="flex pl-[8rem] pt-8" src={logo} />
            <div className="flex flex-col items-center justify-center pt-4 text-[#5BB318]">
              <h1>Selamat Datang !</h1>
              <h1>Muchtaram</h1>
            </div>

            <div className="flex flex-col gap-4 pt-4 justify-center items-center text-green-500">
              <div
                className={`flex justify-start px-4 gap-4 items-center w-[206px] text-base  h-[44px] rounded-md   text-[#5BB318] ${
                  activePage === "/Home" ? "bg-[#5BB318] rounded-md w-[206px]  h-[44px]  text-base text-white items-center" : "text-[#5BB318] w-[206px] text-base  h-[44px] "
                }`}
              >
                <span onClick={() => handleButtonClick("/Home")} className="cursor-pointer ">
                  {activePage === "/Home" ? (
                    <img className="" src={Home1} alt="Home" />
                  ) : (
                    <svg className="stroke-[#5BB318] hover:stroke-[#1E3C08] " width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3 9.4646L12 2.4646L21 9.4646V20.4646C21 20.995 20.7893 21.5037 20.4142 21.8788C20.0391 22.2539 19.5304 22.4646 19 22.4646H5C4.46957 22.4646 3.96086 22.2539 3.58579 21.8788C3.21071 21.5037 3 20.995 3 20.4646V9.4646Z"
                        // stroke="#5BB318"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 22.4646V12.4646H15V22.4646"
                        // stroke="#5BB318"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </span>
                <button onClick={() => handleButtonClick("/Home")} className="text-lg">
                  Dashboard
                </button>
              </div>
              <div
                className={`flex justify-start px-5 gap-4 items-center w-[206px] text-base  h-[44px] rounded-md   text-[#5BB318] ${
                  activePage === "/Riwayat" ? "bg-[#5BB318] rounded-md w-[206px]  h-[44px]  text-base text-white items-center" : "text-[#5BB318] w-[206px] text-base  h-[44px] "
                }`}
              >
                <span onClick={() => handleButtonClick("/Riwayat")} className="cursor-pointer">
                  {activePage === "/Riwayat" ? (
                    <img className="w-[1.3rem]" src={History1} alt="History" />
                  ) : (
                    <svg className="fill-[#5BB318] hover:fill-[#1E3C08] " width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 18.4646C6.7 18.4646 4.69583 17.7021 2.9875 16.1771C1.27917 14.6521 0.3 12.748 0.05 10.4646H2.1C2.33333 12.198 3.10417 13.6313 4.4125 14.7646C5.72083 15.898 7.25 16.4646 9 16.4646C10.95 16.4646 12.6042 15.7855 13.9625 14.4271C15.3208 13.0688 16 11.4146 16 9.46463C16 7.51463 15.3208 5.86046 13.9625 4.50213C12.6042 3.1438 10.95 2.46463 9 2.46463C7.85 2.46463 6.775 2.7313 5.775 3.26463C4.775 3.79796 3.93333 4.5313 3.25 5.46463H6V7.46463H0V1.46463H2V3.81463C2.85 2.74796 3.8875 1.92296 5.1125 1.33963C6.3375 0.756297 7.63333 0.46463 9 0.46463C10.25 0.46463 11.4208 0.70213 12.5125 1.17713C13.6042 1.65213 14.5542 2.2938 15.3625 3.10213C16.1708 3.91046 16.8125 4.86046 17.2875 5.95213C17.7625 7.0438 18 8.21463 18 9.46463C18 10.7146 17.7625 11.8855 17.2875 12.9771C16.8125 14.0688 16.1708 15.0188 15.3625 15.8271C14.5542 16.6355 13.6042 17.2771 12.5125 17.7521C11.4208 18.2271 10.25 18.4646 9 18.4646ZM11.8 13.6646L8 9.86463V4.46463H10V9.06463L13.2 12.2646L11.8 13.6646Z" />
                    </svg>
                  )}
                </span>
                <button onClick={() => handleButtonClick("/Riwayat")} className="text-lg">
                  Riwayat
                </button>
              </div>

              <div
                className={`flex justify-start px-5 gap-4 items-center w-[206px] text-base  h-[44px] rounded-md   text-[#5BB318] ${
                  activePage === "/status" ? "bg-[#5BB318] rounded-md w-[206px]  h-[44px]  text-base text-white items-center" : "text-[#5BB318] w-[206px] text-base  h-[44px] "
                }`}
              >
                <span onClick={() => handleButtonClick("/status")} className="cursor-pointer">
                  {activePage === "/status" ? (
                    <img className="w-[1.3rem]" src={Status1} alt="status1" />
                  ) : (
                    <svg className="fill-[#5BB318] hover:fill-[#1E3C08] " width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 18.4646V16.4646H20V18.4646H0ZM1 15.4646V8.4646H4V15.4646H1ZM6 15.4646V3.4646H9V15.4646H6ZM11 15.4646V6.4646H14V15.4646H11ZM16 15.4646V0.4646H19V15.4646H16Z" />
                    </svg>
                  )}
                </span>
                <button onClick={() => handleButtonClick("/status")} className="text-lg">
                  Status
                </button>
              </div>
              <div className="flex justify-start px-5 pt-[6rem] gap-4 items-center w-[206px] text-base  h-[44px] rounded-md   text-[#5BB318] ">
                <img className="w-[1.3rem]" src={Logout1} alt="Logout" />
                <button onClick={togglePopup} className="flex items-center text-lg ">
                  Logout
                </button>
                {isOpen && (
                  <div className="fixed inset-0 flex items-center justify-center z-20">
                    <div className="bg-black opacity-50 absolute inset-0"></div>
                    <div className="bg-[#F8F9FD] w-[350px] h-[350px] rounded-lg z-20  flex flex-col  justify-center items-center  ">
                      <div className=" flex flex-col justify-center items-center w-[243px] space-y-1 ">
                        <img className="" src={LogoutPop} alt="Status" />
                        <p className="text-[#2E5A0C] font-semibold text-lg">Logout?</p>
                        <p className="text-[#818286] text-xs text-center ">Logout membantu menjaga keamanan akun Anda. Silahkan konfirmasi logout.</p>
                      </div>
                      <div className="flex gap-4">
                        <button onClick={togglePopup} className="bg-[#5BB318]  text-white font-medium text-sm w-[119px] h-[40px] rounded-lg mt-4">
                          Tidak
                        </button>
                        <button onClick={() => navigate("/")} className="border-[#5BB318] border-3  text-[#5BB318] font-medium text-sm w-[119px] h-[40px]  rounded-lg mt-4">
                          Ya
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </NavbarMenuItem>
        </NavbarMenu>
      </Navbar>
    </div>
  );
};
