import React, { useState } from "react";
import { getAuth, signOut } from "firebase/auth"; // Import Firebase Auth
import logo from "../img/Group 19.svg";
import Status1 from "../img/status1.svg";
import History1 from "../img/history1.svg";
import Home1 from "../img/home1.svg";
import Logout from "../img/logout.svg";
import LogoutPop from "../img/Logout.png";
import { useLocation, useNavigate } from "react-router-dom";

export const ButtonSide = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  const [isOpen, setIsOpen] = useState(false);
  const [hover, isHover] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = (path) => {
    navigate(path);
    setActivePage(path);
  };

  const handleLogout = async () => {
    const auth = getAuth(); // Initialize Firebase Auth
    try {
      await signOut(auth); // Sign out user
      navigate("/"); // Redirect to the login or homepage
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const hoverClass = hover ? "hover:bg-[#ADD98B] hover:text-[#1E3C08] hover:rounded-md" : "";
  const hoverClass2 = hover ? "hover:stroke-[#1E3C08]" : "";

  const handleHover = () => {
    isHover(true);
  };

  return (
    <div className="flex flex-col bg-[#F8F9FD] w-fit h-fit text-white mobile:hidden desktop:flex">
      <div className="flex justify-center items-center pt-[2rem]">
        <img className="w-[5.5rem]" src={logo} alt="Logo" />
      </div>
      <div className="flex flex-col pt-[3rem] gap-6 px-4">
        <div
          onMouseEnter={handleHover}
          className={`gap-[1rem] flex items-center ${
            activePage === "/Home" ? "bg-[#5BB318] rounded-md w-[218px] p-2 " : `text-[#5BB318] w-[218px] p-2 ${hoverClass}`
          }`}
        >
          <span onClick={() => handleButtonClick("/Home")} className="cursor-pointer">
            {activePage === "/Home" ? (
              <img className="w-[1.3rem]" src={Home1} alt="Home" />
            ) : (
              <svg
                className={`stroke-[#5BB318] ${hoverClass2}`}
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 9.4646L12 2.4646L21 9.4646V20.4646C21 20.995 20.7893 21.5037 20.4142 21.8788C20.0391 22.2539 19.5304 22.4646 19 22.4646H5C4.46957 22.4646 3.96086 22.2539 3.58579 21.8788C3.21071 21.5037 3 20.995 3 20.4646V9.4646Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22.4646V12.4646H15V22.4646"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </span>

          <button onClick={() => handleButtonClick("/Home")} className="flex items-center text-md font-semibold">
            Dashboard
          </button>
        </div>

        {/* Button Logout */}
        <div className="gap-[1rem] flex items-center mt-[61rem] mb-[2rem] text-[#5BB318] w-[218px]  p-2 hover:bg-[#ADD98B] hover:text-[#1E3C08] hover:rounded-md ">
          <img className="w-[1.3rem]" src={Logout} alt="Logout" />
          <button onClick={togglePopup} className="flex items-center text-lg font-semibold">
            Logout
          </button>
          {isOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-20">
              <div className="bg-[#F8F9FD] w-[577px] h-[405px] rounded-lg z-20  flex flex-col justify-center items-center  ">
                <div className="flex flex-col justify-center items-center w-[481px] space-y-3">
                  <img src={LogoutPop} alt="Status" />
                  <p className="text-[#2E5A0C] font-semibold text-2xl">Logout?</p>
                  <p className="text-[#818286] text-sm text-center">
                    Logout membantu menjaga keamanan akun Anda. Silahkan konfirmasi logout.
                  </p>
                </div>
                <div className="flex gap-4">
                  <button onClick={togglePopup} className="bg-[#5BB318] text-white font-medium text-2xl w-[232.5px] h-[60px] rounded-lg mt-4">
                    Tidak
                  </button>
                  <button onClick={handleLogout} className="border-[#5BB318] border-3 text-[#5BB318] font-medium text-2xl w-[232.5px] h-[60px] rounded-lg mt-4">
                    Ya
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
