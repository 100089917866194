import React, { useEffect, useState } from "react";
import { ButtonSide } from "../assets/component/ButtonSide";
import Profil from "../assets/img/Pak Muchtarom.png";
import { NavbarResponsive } from "../assets/component/NavbarResponsive";
import { Bar } from "react-chartjs-2";

import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

export const Status = () => {
  const [sensorData, setSensorData] = useState([]);

  const data = {
    labels: ["14 Februari", "15 Februari", "16 Februari", "17 Februari", "18 Februari", "19 Februari", "20 Februari"],
    datasets: [
      {
        label: "Jumlah Air",
        data: [82, 85, 88, 91, 93, 96, 100],
        backgroundColor: "#4E77F2",
        borderColor: "#95ADF7",
        pointBorderColor: "transparent",
        pointBorderWidth: 4,
      },
      {
        label: "Debit Air",
        data: [7.8, 8, 9, 6.9, 5.9, 8.4, 7.8, 6.7],
        backgroundColor: "#95ADF7",
        borderColor: "#95ADF7",
        pointBorderColor: "transparent",
        pointBorderWidth: 4,
      },
    ],
  };

  const options = {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://adosistering-default-rtdb.asia-southeast1.firebasedatabase.app/SensorKelembaban.json");

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setSensorData(data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex w-full h-[100%] bg-[#EBECF0]">
      <div className="flex w-full h-fit  ">
        <ButtonSide />
        <div className="w-full h-full  flex flex-col justify-center items-center desktop:space-y-8 mobile:space-y-4">
          <div className="p-6 bg-[#F8F9FD] desktop:h-[100px] mobile:h-[61px] flex w-full  justify-between mobile:hidden desktop:flex">
            <h1 className="font-semibold text-[#5BB318]  desktop:text-[32px] ">Status</h1>
            <div className="flex w-full justify-end items-center desktop:gap-4 mobile:gap-3">
              <img className=" " src={Profil} alt="Profil" />
              <span className="mobile:hidden desktop:flex flex-col text-[#5BB318] text-[16px]">
                <p className="">Selamat Datang !</p>
                <p className="font-medium">Muchtar</p>
              </span>
            </div>
          </div>
          <NavbarResponsive className="desktop:hidden" />
          <div className="w-full desktop:px-10 mobile:px-3 desktop:space-y-4 mobile:space-y-4 flex flex-col items-center justify-center">
            <div className=" w-full flex items-center justify-center bg-[#F8F9FD]  desktop:h-[64px] mobile:h-[43px] rounded-lg">
              <h1 className="font-semibold text-[#5BB318] desktop:text-2xl mobile:text-base ">Status Irigasi</h1>
            </div>
            <div className="w-full bg-[#F8F9FD] justify-center mobile:items-center desktop:items-start  h-fit flex rounded-lg flex-col p-6 desktop:space-y-4 mobile:space-y-1">
              <h1 className="font-semibold items-start w-full flex desktop:text-2xl mobile:text-lg text-[#5B5C60]  rounded-md ">Lahan 1</h1>
              <div className="flex mobile:flex-col desktop:flex-row gap-[2rem] w-full desktop:text-base mobile:text-xs">
                <div className="bg-[#FDFDFE] desktop:w-[50%] mobile:w-full  h-fit flex flex-col justify-center space-y-1 gap-1 rounded-md p-4">
                  <p className="bg-[#FDF094] desktop:text-base text-center mobile:text-sm font-medium rounded-[4px] px-4 py-1 text-[#7E7115]">Informasi Kelembaban Tanah</p>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs pt-2">
                    <p>Kadar Air</p>
                    <p className="text-[#818286] font-medium">{sensorData.KadarAir} % PH</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-x">
                    <p>Kelembaban Tanah</p>
                    <p className="text-[#818286] font-medium">{sensorData.Kelembaban} % Rh</p>
                  </div>
                  <div className="justify-between items-center flex desktop:text-sm mobile:text-x">
                    <p>Kondisi Tanah</p>
                    <p className="bg-[#CBF7A8] text-[#2E5A0C] px-4 py-1 rounded-[36px]">Baik</p>
                  </div>
                </div>

                <div className="bg-[#FDFDFE] desktop:w-[50%] mobile:w-full  h-fit flex flex-col justify-center space-y-1  gap-1 rounded-md p-4">
                  <p className="bg-[#95ADF7] text-base font-medium text-center rounded-[4px] px-4 py-1 text-[#1D3DA0]">Informasi Kelembaban Tanah</p>
                  <div className="flex justify-between text-sm pt-2">
                    <p>Total Air Keluar</p>
                    <p className="text-[#818286] font-medium">21 Liter</p>
                  </div>
                  <div className="flex justify-between text-sm ">
                    <p>Rata- Rata Debit Air</p>
                    <p className="text-[#818286] font-medium">6.4 L / menit</p>
                  </div>
                  <div className="justify-between items-center flex text-sm">
                    <p>Jenis Tanaman</p>
                    <p className="bg-[#95ADF7] text-[#1D3DA0] px-4 py-1 rounded-[36px]">Padi</p>
                  </div>
                </div>
              </div>
            </div>

            {/* card 2 */}
            <div className="w-full bg-[#F8F9FD] justify-center mobile:items-center desktop:items-start h-fit flex rounded-lg flex-col p-6 desktop:space-y-4 mobile:space-y-1">
              <h1 className="font-semibold items-start w-full flex desktop:text-2xl mobile:text-lg text-[#5B5C60]  rounded-md ">Keterangan </h1>
              <div className="flex mobile:flex-col desktop:flex-row gap-[2rem] w-full desktop:text-base mobile:text-xs">
                <div className="bg-[#FDFDFE] desktop:w-[50%] mobile:w-full  h-fit flex flex-col justify-center space-y-1 gap-1 rounded-md p-4">
                  <p className="bg-[#CBF7A8] desktop:text-base mobile:text-sm font-medium text-center  rounded-[4px] px-4 py-1 text-[#2E5A0C]"> Kelembaban Tanah</p>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs pt-2">
                    <p>sangat kering</p>
                    <p className="text-[#818286] font-medium">&lt; 10%</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-x">
                    <p>Kering</p>
                    <p className="text-[#818286] font-medium">10% - 30%</p>
                  </div>
                  <div className="justify-between items-center flex desktop:text-sm mobile:text-x">
                    <p>Cukup Kering</p>
                    <p className="text-[#818286]">30% - 50%</p>
                  </div>
                  <div className="flex items-center justify-between desktop:text-sm mobile:text-x">
                    <p>Tanah Lembap</p>
                    <p className="text-[#818286]">50% - 70%</p>
                  </div>
                  <div className="flex items-center justify-between desktop:text-sm mobile:text-x">
                    <p>Tanah Basah</p>
                    <p className="text-[#818286]">&gt;70%</p>
                  </div>
                </div>
                {/* ))} */}

                <div className="bg-[#FDFDFE] desktop:w-[50%] mobile:w-full h-fit flex flex-col justify-center space-y-1  gap-1 rounded-md p-4">
                  <p className="bg-[#95ADF7] desktop:text-base mobile:text-sm font-medium rounded-[4px] px-4 py-1 text-[#1D3DA0] text-center">Kadar Air</p>
                  <div className="flex justify-between text-sm">
                    <p>Sangat Kering</p>
                    <p className="text-[#818286] font-medium">&lt; 5%</p>
                  </div>
                  <div className="flex justify-between text-sm ">
                    <p>Kering</p>
                    <p className="text-[#818286] font-medium">5% - 10%</p>
                  </div>
                  <div className="justify-between items-center flex text-sm">
                    <p>Cukup Kering</p>
                    <p className="text-[#818286] ">10% - 20%</p>
                  </div>
                  <div className="flex justify-between text-center  text-sm">
                    <p>Tanah Lembab</p>
                    <p className="text-[#818286]">20% - 40%</p>
                  </div>
                  <div className="flex justify-between text-center text-sm">
                    <p>Tanah Basah</p>
                    <p className="text-[#818286]">&gt;70%</p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-full flex flex-col items-center justify-center bg-[#F8F9FD]  desktop:h-fit mobile:h-fit rounded-lg">
              <h1 className="font-semibold items-start w-full flex desktop:text-2xl p-6  mobile:text-lg text-[#5B5C60]  rounded-md ">Statistik Penggunaan Air</h1>
              <div className="  w-[96%] h-fit  flex justify-center  p-6">
                <div className=" desktop:items-center flex mobile:items-start justify-center" style={{ width: "100%", height: "" }}>
                  <Bar data={data} options={options}></Bar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
