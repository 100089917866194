import React, { useEffect, useState } from "react";
import { ButtonSide } from "../assets/component/ButtonSide";
import Profil from "../assets/img/profil.png";
import { NavbarResponsive } from "../assets/component/NavbarResponsive";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get, set, onValue, off } from "firebase/database";

export const Home = () => {
  const [soilMoisture, setSoilMoisture] = useState("-");
  const [waterFlow, setWaterFlow] = useState("-");
  const [showTotalAir, setShowTotalAir] = useState(false);
  const [userName, setUserName] = useState("");

  const auth = getAuth();
  const db = getDatabase();

  // Fungsi untuk mengubah status toggle
  const handleToggle = () => {
    setShowTotalAir(!showTotalAir);

    const user = auth.currentUser;
    if (user) {
      const uid = user.uid;
      const status = !showTotalAir ? 1 : 0; // Toggle status relay di Firebase
      updateRelayStatus(uid, status);

      // Set default nilai saat relay mati
      if (status === 0) {
        setSoilMoisture("-");
        setWaterFlow("-");
      }
    }
  };

  // Fungsi untuk mengupdate status relay pada Firebase
  const updateRelayStatus = async (uid, status) => {
    const relayRef = ref(db, `users/${uid}/relay`);
    try {
      await set(relayRef, status);
      console.log(`Relay status updated to: ${status}`);
    } catch (error) {
      console.error("Error updating relay status: ", error);
    }
  };

  // Ambil data soil moisture dan water flow dari Firebase hanya saat relay ON
  useEffect(() => {
    const user = auth.currentUser;
    let soilMoistureRef;
    let waterFlowRef;

    if (user) {
      const uid = user.uid;

      const relayRef = ref(db, `users/${uid}/relay`);

      // Pantau perubahan status relay
      onValue(relayRef, (snapshot) => {
        if (snapshot.exists()) {
          const relayStatus = snapshot.val();
          setShowTotalAir(relayStatus === 1);

          if (relayStatus === 1) {
            // Relay ON, ambil data real-time
            soilMoistureRef = ref(db, `users/${uid}/sensors/soilMoisture`);
            waterFlowRef = ref(db, `users/${uid}/sensors/waterFlow`);

            onValue(soilMoistureRef, (snapshot) => {
              setSoilMoisture(snapshot.exists() ? snapshot.val() : "-");
            });

            onValue(waterFlowRef, (snapshot) => {
              setWaterFlow(snapshot.exists() ? snapshot.val() : "-");
            });
          } else {
            // Relay OFF, hentikan sinkronisasi
            if (soilMoistureRef) off(soilMoistureRef);
            if (waterFlowRef) off(waterFlowRef);
            setSoilMoisture("-");
            setWaterFlow("-");
          }
        }
      });
    }

    // Cleanup listener saat komponen unmount
    return () => {
      if (soilMoistureRef) off(soilMoistureRef);
      if (waterFlowRef) off(waterFlowRef);
    };
  }, [auth, db]);

  // Ambil nama pengguna berdasarkan UID yang login
  useEffect(() => {
    const fetchUserName = async () => {
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const userRef = ref(db, `users/${uid}`);
        try {
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            setUserName(snapshot.val().name);
          } else {
            console.log("No user data found");
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      }
    };

    fetchUserName();
  }, [auth, db]);

  return (
    <div className="flex w-full h-full bg-[#EBECF0]">
      <div className="flex w-full h-fit">
        <ButtonSide />

        <div className="w-full h-full flex flex-col justify-center items-center desktop:space-y-8 mobile:space-y-4">
          <div className="p-6 bg-[#F8F9FD] desktop:h-[100px] mobile:h-[61px] flex w-full justify-between desktop:flex mobile:hidden">
            <h1 className="font-semibold text-[#5BB318] desktop:text-[32px]">
              Dashboard
            </h1>
            <div className="flex w-full justify-end items-center desktop:gap-4 mobile:gap-3">
              <img src={Profil} alt="Profil" />
              <span className="mobile:hidden desktop:flex flex-col text-[#5BB318] text-[16px]">
                <p>Selamat Datang!</p>
                <p className="font-medium">{userName || "user"}</p>
              </span>
            </div>
          </div>

          <NavbarResponsive className="desktop:hidden" />

          <div className="w-full desktop:px-10 mobile:px-3 desktop:space-y-4 mobile:space-y-4 flex flex-col items-center justify-center">
            <div className="w-full flex items-center justify-center bg-[#F8F9FD] desktop:h-[64px] mobile:h-[43px] rounded-lg">
              <h1 className="font-semibold text-[#5BB318] desktop:text-2xl mobile:text-base">
                Daftar Lahan
              </h1>
            </div>

            <div className="flex flex-wrap items-center mobile:justify-center desktop:justify-between gap-6 w-full">
              <div className="bg-[#F8F9FD] flex flex-col justify-center px-6 desktop:w-[352px] mobile:w-full text-[#3F4044] py-6 rounded-lg space-y-2">
                <h1 className="font-medium desktop:text-2xl mobile:text-base">
                  Lahan 1
                </h1>
                <div className="flex flex-col gap-2.5 text-sm">
                  <div className="flex justify-between items-center desktop:text-sm mobile:text-xs py-1">
                    <p>Kelembapan Tanah</p>
                    <p className="text-[#4C9514] bg-[#CBF7A8] px-3 py-1 rounded-xl font-medium">
                      {soilMoisture} %
                    </p>
                  </div>
                  <div className="flex justify-between items-center desktop:text-sm mobile:text-xs py-1">
                    <p>Debit Air</p>
                    <p className="text-[#4C9514] bg-[#CBF7A8] px-3 py-1 rounded-xl font-medium">
                      {waterFlow} L/Menit
                    </p>
                  </div>
                  <div className="flex justify-between items-center desktop:text-sm mobile:text-xs py-1">
                    <p>Irigasi</p>
                    {showTotalAir ? (
                      <p className="text-[#4C9514] bg-[#CBF7A8] px-3 py-1 rounded-xl font-medium">
                        On
                      </p>
                    ) : (
                      <p className="text-[#FF0000] bg-[#FFDDDD] px-3 py-1 rounded-xl font-medium">
                        Off
                      </p>
                    )}
                  </div>

                  {/* Toggle Switch */}
                  <div className="flex items-center mt-2">
                    <label className="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only"
                        checked={showTotalAir}
                        onChange={handleToggle}
                      />
                      <div
                        className={`w-10 h-5 rounded-full p-1 flex items-center transition-all duration-300 ${
                          showTotalAir ? "bg-[#4C9514]" : "bg-[#E5E5E5]"
                        }`}
                      >
                        <div
                          className={`w-4 h-4 bg-white rounded-full transition-all duration-300 ${
                            showTotalAir ? "translate-x-5" : "translate-x-0"
                          }`}
                        ></div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
